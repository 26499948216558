import { useEffect } from "react";

import PageContainer from "../components/containers/PageContainer";
import Sidebar from "../components/containers/Sidebar";

import profile from "../images/profile.jpg";

import classes from "./About.module.css";

function About() {
  useEffect(() => {
    document.title = "Dana Boston - About";
  }, []);

  return (
    <PageContainer title="Dana Boston">
      <Sidebar title="Dana Boston" />
      <div className={classes.aboutHolder}>
        <img
          className="profileImage"
          src={profile}
          alt="Dana on the Brooklyn Bridge in NYC with a sunset over Manhattan in the background"
        />
        <div className={classes.aboutContent}>
          Thanks for stopping by! <br /> <br />
          My name is Dana Boston and I am a 24 year old full-stack software
          engineer at BBI Logistics. From October 2022 - April 2023 I attended
          the OSU Coding Bootcamp where I learned the essentials to make my way
          into this career path. Since then, I have been working at BBI
          Logistics since May 2023 in the main office located in downtown Columbus.
          <br />
          <br />
          My current work includes planning and building internal transportation
          management systems from the ground up. As part of that process I am
          tasked with developing new features as well as maintaining existing
          functionality through on-demand bug fixes. I also perform research on
          different technologies that we could make use of in upcoming projects
          and present what I've gathered to the team on a weekly basis.
          <br />
          <br />
          Prior to my software engineering career I spent nearly seven years
          working in sales and IT support through Best Buy and Geek Squad. After
          spending nearly four years in sales across various departments I moved
          to Geek Squad where I performed software and hardware repairs on
          desktop and mobile devices. During that time I also became a Certified
          Repair Technician through both Apple and Samsung.
          <br />
          <br />
          At home, I have a great support system with my loving girlfriend and
          our children, Ocean, who's almost five, and Ender, who's about to turn
          one! And I can't forget to mention my childhood dog, Freckles, who is
          still with us today and as full of energy as ever! Other interests of
          mine include photography and videography which are both major passions
          I love to dive into. They both go hand-in-hand with my number one
          interest - traveling! Within the past few years I've been to quite a
          few major cities including New York City, Los Angeles, Las Vegas,
          Chicago, and Toronto!
        </div>
      </div>
    </PageContainer>
  );
}

export default About;
