import classes from "./Sidebar.module.css";

import { AiOutlineClose } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { FaAddressCard } from "react-icons/fa";
import { FaFolderOpen } from "react-icons/fa";
import { BiMessageDetail } from "react-icons/bi";
import { FaDownload } from "react-icons/fa";

import { NavLink } from "react-router-dom";

function Sidebar(props) {
  return (
    <div className={classes.sidebarHolder}>
      <div className={classes.title}>{props.title}</div>
      <div className={classes.hamburgerHelper}>
        <label htmlFor="toggle">
          <RxHamburgerMenu />
        </label>
      </div>
      <div className={classes.sidebar}>
        <div className={classes.closeHelper}>
          <label htmlFor="toggle">
            <AiOutlineClose />
          </label>
          <input type="checkbox" id="toggle"></input>
        </div>
        <div className={classes.sidebarItems}>
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? classes.activeLink : undefined
            }
          >
            <FaAddressCard /> About
          </NavLink>
          <NavLink
            to="/portfolio"
            className={({ isActive }) =>
              isActive ? classes.activeLink : undefined
            }
          >
            <FaFolderOpen /> Portfolio
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? classes.activeLink : undefined
            }
          >
            <BiMessageDetail /> Contact
          </NavLink>
          <a
            href={
              "https://drive.google.com/uc?export=download&id=1BRtztsk3P3_aB_36LqjBIzpCY75WApSh"
            }
            download="Dana Boston's Resume"
          >
            <FaDownload /> Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
