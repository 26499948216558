import { useEffect } from "react";

import classes from "./Work.module.css";

import PageContainer from "../components/containers/PageContainer";
import Sidebar from "../components/containers/Sidebar";

import ExampleWorks from "../components/WorkModule";

function Work() {
  useEffect(() => {
    document.title = "Dana Boston - Portfolio";
  }, []);

  return (
    <PageContainer title="Portfolio">
      <Sidebar title="Portfolio" />
      <article className={classes.workBox}>
        <ExampleWorks />
      </article>
    </PageContainer>
  );
}

export default Work;
