import classes from '../pages/Work.module.css';

import pokedexMini from '../images/pokedex-mini.png';
import profileGenerator from '../images/profile-generator.png';
import JATE from '../images/JATE.png'
import socialApi from '../images/social-api.jpg'

function WorkModule(props) {
    return (
        <figure className={classes.workModule}>
            <a href={props.deployedApp}><img src={props.image} alt={props.alt} /></a>
            <figcaption>{props.name}</figcaption>
            <p>{props.desc}</p>
            <a href={props.github} className={classes.githubLink}>Github Repo</a>
        </figure>
    )
}

function ExampleWorks() {
    return (
        <>
            <WorkModule
                deployedApp='https://retrotechcode.github.io/Pokedex-Mini'
                image={pokedexMini}
                alt="The homepage containing an image of a Pokedex, search bar, and the user's five most recent searches."
                name='Pokedex Mini'
                desc={`This was my first collaborative project during my experience in the OSU Bootcamp. It is a
                front-end application allowing users to search for any Pokemon using either the Pokemon's name or
                their Pokedex number via PokeAPI and PoGoAPI. It then displays basic information on that Pokemon, including
                images, typing, base stats, and a description of the Pokemon.`}
                github='https://github.com/RetroTechCode/Pokedex-Mini'
            />
            <WorkModule
                deployedApp='https://github.com/RetroTechCode/team-profile-generator'
                image={profileGenerator}
                alt='Example team profile page generated from the backend code. Red banner across the top stating "Generated Team
                    Profile" along with 5 example team members in cards containing their name, job title, ID, email address, and office number, github link, or school.'
                name='Team Profile Generator'
                desc={`The team profile generator is a back-end application that takes user input from the terminal via Inquirer and generates the front-end HTML
                    and CSS needed to create a basic team profile page to show information about your employees. This app also contains tests using Jest that I
                    used during the development process.`}
                github='https://github.com/RetroTechCode/team-profile-generator'
            />
            <WorkModule
                deployedApp='https://salty-tor-76149.herokuapp.com/'
                image={JATE}
                alt='A text editor with JATE in ascii text in the main section with a light blue header above containing an install button, "Just Another Text Editor" title, and the JATE logo'
                name='JATE'
                desc={`Just Another Text Editor is exactly that - a text editor. This PWA allows the user to write text in their browser using JavaScript syntax. Beyond that, it also works offline
                and can be installed directly onto the user's machine for easy access! My work on this project included configuring the webpack, service workers, and IndexedDB.`}
                github='https://github.com/RetroTechCode/pwa-text-editor'
            />
            <WorkModule 
                deployedApp='https://github.com/RetroTechCode/social-network-api'
                image={socialApi}
                alt='Insomnia application open showing the results of a GET request'
                name='Social Network API'
                desc={`This back-end application was built as a proof of concept for setting up the API for a social networking app. Users can use Insomnia to test all of the CRUD operations to
                interact with mock user accounts, friends lists, posts (called "thoughts"), and replies (called reactions). The database is set up using Mongoose and routed through Express.`}
                github='https://github.com/RetroTechCode/social-network-api'
            />
        </>
    );
};

export default ExampleWorks;