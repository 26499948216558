import Header from './Header';

import classes from './PageContainer.module.css';

function PageContainer(props) {
    return (
        <>
            <Header page={props.title} />
            <section className={classes.pageBody}>
                {props.children}
            </section>
        </>
    );
};

export default PageContainer;