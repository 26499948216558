import banner from '../../images/banner.png'

function Header(props) {
    return (
        <header className="header">
            <img className="banner" src={banner} alt="Geometric grid with orange, gray, and white triangles" onContextMenu={(e)=> e.preventDefault()} draggable="false" />
            <h1 className="bannerText">{props.page}</h1>
        </header>
    );
};

export default Header;