import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import classes from "./Form.module.css";

function ContactForm() {
  const form = useRef();

  const [nameInput, setName] = useState("");
  const [emailInput, setEmail] = useState("");
  const [messageInput, setMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (nameInput && emailInput && messageInput) {
      emailjs
        .sendForm(
          "service_pl4ncn8",
          "template_6loyrtf",
          form.current,
          "vrAsqs2i8xyLpu9sC"
        )
        .then(
          (result) => {
            setSuccessMessage(true);
            e.target.reset();
          },
          (error) => {}
        );
    }
  };

  return (
    <div className={classes.form}>
      <p>Send me a message!</p>
      <form
        ref={form}
        onSubmit={sendEmail}
        className={classes.formItemContainer}
      >
        <label className={classes.formLabel}>Name</label>
        <input
          type="text"
          name="name"
          className={classes.input}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <br />
        <label className={classes.formLabel}>Email</label>
        <input
          type="email"
          name="email"
          className={classes.input}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <br />
        <label className={classes.formLabel}>Message</label>
        <textarea
          name="message"
          className={classes.message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />

        <input type="submit" value="Send" className={classes.send} />
        {successMessage ? <label>Message sent successfully!</label> : ""}
      </form>
    </div>
  );
}

export default ContactForm;
