import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Footer from "./components/containers/Footer";

import About from "./pages/About";
import Work from "./pages/Work";
import Contact from "./pages/Contact";

function App() {
  return (
    <>
      <Router>
        <Routes className="main">
          <Route path="/" element={<About />} />
          <Route path="/portfolio" element={<Work />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
