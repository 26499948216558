import { FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa'


function Footer() {
    return (
        <div className='footer'>
            <ul className='footerIcons'>
                <li><a href="https://github.com/RetroTechCode" rel="noreferrer" target="_blank" className="contactLink"><FaGithub /></a></li>
                <li><a href="https://www.linkedin.com/in/dana-boston-4b082421b/" rel="noreferrer" target="_blank" className="contactLink"><FaLinkedin /></a></li>
                <li><a href="mailto:danaaboston@gmail.com" rel="noreferrer" className="contactLink"><FaEnvelope /></a></li>
            </ul>
        </div>
    );
};

export default Footer;