import { useEffect } from 'react';

import PageContainer from "../components/containers/PageContainer";
import Sidebar from "../components/containers/Sidebar";

import ContactForm from '../components/Form';

function Contact() {
    useEffect(() => {
        document.title = "Dana Boston - Contact"
    }, [])

    return (
        <PageContainer title='Contact Me'>
            <Sidebar title='Contact Me'/>
            <ContactForm />
        </PageContainer>
    );
};

export default Contact;